<template>
  <div class="detail-wrapper">
    <!-- <div class="taskDialog"> -->
        <el-dialog :visible="taskDialog" top="10vh" @close="taskDialogClose" @open="taskDialogOpen" :close-on-click-modal="false">
            <div class="taskselect">
                <div class="title">任务详情</div>
                <el-form :model="taskForm" label-position="top">
                    <div class="formDiv">
                        <el-form-item label="人员/设备">
                            <el-input v-model="taskForm.selectedDevices" readonly/>
                        </el-form-item>
                        <el-form-item v-if="type=='assembly'" label="协作人员/设备">
                            <el-input v-model="taskForm.collaborators" readonly/>
                        </el-form-item>
                    </div>
                    <div class="formDiv">
                        <el-form-item label="工单号"><el-input v-model="taskForm.orderNo" readonly/></el-form-item>
                        <el-form-item label="排产号"><el-input v-model="taskForm.schedulingNo" readonly/></el-form-item>
                        <el-form-item label="需求日期">
                            <el-date-picker v-model="taskForm.orderCompletionDate" type="date" value-format="yyyy-MM-dd" readonly/>
                        </el-form-item>
                        <el-form-item label="产品名称"><el-input v-model="taskForm.productName" readonly/></el-form-item>
                        <el-form-item label="图号"><el-input v-model="taskForm.partCode" readonly/></el-form-item>
                        <el-form-item label="版本号"><el-input v-model="taskForm.versionCode" readonly/></el-form-item>
                        <el-form-item v-if="taskForm.procedureNo" label="工步编号">
                            <el-input v-model="taskForm.procedureNo" readonly/>
                        </el-form-item>
                        <el-form-item v-else label="工步名称">
                            <el-input v-model="taskForm.procedureName" readonly/>
                        </el-form-item>
                        <el-form-item label="排产数量">
                            <el-input v-model="taskForm.plannedQuantity" readonly><template #suffix>件</template></el-input>
                        </el-form-item>
                    </div>
                    <div class="formDiv">
                        <el-form-item label="开始时间">
                            <el-date-picker 
                                v-model="taskForm.procedureStartTime" 
                                type="datetime" placeholder="选择开始时间"
                                value-format="yyyy-MM-dd HH:mm:ss" 
                                :editable="false" :clearable="false" 
                                :id="operationType==3?'bgBlack':'' " 
                                :readonly="operationType!=3" 
                                :picker-options="pickerOptions"
                                prefix-icon="el-icon-date"
                                default-time="00:00:00">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间">
                            <el-date-picker v-model="taskForm.procedureEndTime" type="datetime" placeholder="选择结束时间" readonly
                            prefix-icon="el-icon-date"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="完成周期">
                            <el-input v-model="taskForm.totalTimeStr" readonly/>
                        </el-form-item>
                        <el-form-item label="设定时间">
                            <el-input v-model="taskForm.shuntingTime" readonly><template #suffix>分钟</template></el-input>
                        </el-form-item>
                        <el-form-item label="订单优先级"><el-input v-model="taskForm.priority" readonly/></el-form-item>
                        <el-form-item label="交付情况"><el-input :class="taskForm.overdueStatus==0?'bgGreen':'bgRed'" v-model="taskForm.varianceDay" readonly/></el-form-item>
                    </div>
                    <div class="formEditDiv" v-if="taskForm.scheduledStatus!=2">
                        <el-form-item label="" v-premiSub="'设备甘特图_修改任务'">
                            <el-radio-group v-model="operationType" @input="operationTypeChange" >
                                <el-radio v-if="type!='assembly'" :disabled="taskForm.completedQuantity && taskForm.completedQuantity>0" :label="1">修改工步优先级</el-radio>
                                <el-radio :disabled="taskForm.completedQuantity && taskForm.completedQuantity>0" :label="3">更改人员/设备</el-radio>
                                <el-radio  @click.native.prevent="splitClick" :label="2">拆分</el-radio>
                                <el-radio v-if="type!='assembly'" :label="4">修改算法</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <!-- 可更改的工步优先级 -->
                        <el-select v-if="operationType==1" v-model="taskForm.processingRoutePriority" >
                            <el-option v-for="(item,index) in taskForm.routePriority" :key="index" :label="item" :value="item"></el-option>
                        </el-select>

                        <!-- 可更换的人员/设备 -->
                         <div v-if="operationType==3">
                            <el-select v-if="type=='assembly'" v-model="taskForm.selectedStaff" >
                                <el-option v-for="(item,index) in taskForm.supportedStaffInfos" :key="index" :label="item.staffName" :value="item.staffNo"></el-option>
                            </el-select>
                            <el-select v-else v-model="taskForm.selectedDevices">
                                <el-option v-for="(item,index) in taskForm.supportedEquipmentNos" :key="index" :label="item" :value="item"></el-option>
                            </el-select>
                         </div>
                        

                        <!-- 拆分弹框 -->
                        <div v-if="splitDialog" class="splitDialog">
                            <div class="colseIcon" @click.stop="splitDialog=false;operationType=1">×</div>
                            <div class="splitTitle">拆分</div>
                            <div class="splitHead">
                                <div>人员/设备</div>
                                <div>安排数量</div>
                                <div>开始时间</div>
                            </div>
                            <div v-for="(item,index) in splitArr" :key="index" class="splitNo">
                                <div class="taskno">任务{{index+1}}： </div> 

                                <div v-if="type=='assembly'">
                                    <!-- <el-select v-model="item.staffNos" :id="index==0?'bgD3':''" 
                                    multiple :disabled="index==0">
                                        <el-option v-for="(item,index) in taskForm.supportedStaffInfos" :key="index" :label="item.staffName" :value="item.staffNo"></el-option>
                                    </el-select>  -->
                                    <el-select v-if="index==0" v-model="item.staffNos" id="bgD3" multiple disabled>
                                        <el-option v-for="(item,index) in taskForm.selectedStaffs" :key="index" :label="item.staffName" :value="item.staffNo"></el-option>
                                    </el-select> 
                                    <el-select v-else v-model="item.staffNos"  multiple >
                                        <el-option v-for="(item,index) in taskForm.supportedStaffInfos" :key="index" :label="item.staffName" :value="item.staffNo"></el-option>
                                    </el-select> 
                                </div>
                                <div v-else>
                                    <el-input v-if="index==0"  id="bgD" v-model="item.equipmentNo" readonly/>
                                    <el-select v-else v-model="item.equipmentNo" >
                                        <el-option v-for="(item,index) in taskForm.supportedEquipmentNos" :key="index" :label="item" :value="item"></el-option>
                                    </el-select> 
                                </div>
                                 
                                <el-input v-model.number="item.splitQuantity">
                                    <template #suffix>件</template>
                                </el-input>

                                <el-date-picker 
                                    v-model="item.startTime" 
                                    type="datetime" 
                                    placeholder="选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss" 
                                    :editable="false" 
                                    :clearable="false" 
                                    prefix-icon="el-icon-time" 
                                    :readonly="index==0" 
                                    :id="index==0?'bgD2':''"
                                    :picker-options="pickerOptions"
                                    default-time="00:00:00">
                                </el-date-picker>
                                <img v-if="index!=0" @click.stop="splitArr.splice(index,1)" src="@/assets/images/gantt/splitdel.png" alt="">
                            </div>
                            <div class="splitAdd" v-if="splitArr.length<10" @click.stop="splitAddClick">+</div>
                            <div class="splitBtn">
                                <el-button @click.stop="splitDialog=false;operationType=''">取 消</el-button>
                                <el-button type="primary" @click.stop="splitCheck">确 定</el-button>
                            </div>
                        </div>

                        <!-- 可修改的算法 -->
                        <el-select v-if="operationType==4" v-model="taskForm.curSchedulingMethod" >
                            <el-option v-for="(item,index) in schedulingMethods" :key="index" :label="item" :value="item"></el-option>
                        </el-select>

                        <div class="formLockDiv" v-premiSub="'设备甘特图_修改任务'">
                            <el-checkbox :disabled="taskForm.completedQuantity && taskForm.completedQuantity>0" v-model="isLock">锁定操作</el-checkbox>
                        </div>
                    </div>
                    
                </el-form>
                <div class="btndiv"  v-if="taskForm.scheduledStatus!=2">
                    <el-button @click="$emit('update:taskDialog',false);splitDialog=false">取 消</el-button>
                    <el-button type="primary" @click="taskSubmit">确 定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 运算方式弹框 -->
        <algorithm-dialog :algoDialog.sync="algoDialog" @algoClick="editSubmit"/>
    </div>
  <!-- </div> -->
</template>

<script>
import dayjs from 'dayjs';
import AlgorithmDialog from './AlgorithmDialog.vue';
export default {
  components: { AlgorithmDialog },
    props:{
        type:String,
        taskDialog:Boolean,
        taskForm:{}
    },
    data(){
        return {
            splitDialog:false,
            algoDialog:false,

            isLock:false, //是否锁定任务
            splitArr:[], //拆分任务数据数组

            operationType:1,

            pickerOptions:{
                disabledDate(time){
                    return time.getTime()<Date.now()-8.64e7;
                },
                selectableRange:`00:00:00 - 23:59:59`
            }
            
        }
    },
    mounted(){
    },
    computed:{
        schedulingMethods(){
            return["FTA", "EE", "ES"]
        }
    },
    watch:{
        // 'taskForm.procedureStartTime'(){
        //     if(dayjs(this.taskForm.procedureStartTime).valueOf()<=Date.now()){
        //         this.taskForm.procedureStartTime=dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss");
        //         this.pickerOptions.selectableRange =`${Date().split(" ")[4]} - 23:59:59`;
        //     }else{
        //         this.pickerOptions.selectableRange =`00:00:00 - 23:59:59`;
        //     }
            
        // },

        'splitArr': {
            deep: true,
            handler(newVal) {
                newVal.forEach((item, index) => {
                    this.$watch(() => item.startTime, () => {
                        // console.log(`item ${index}: ${oldName} -> ${newName}`);
                        if(dayjs(item.startTime).valueOf()<=Date.now()){
                            this.splitArr[index].startTime=dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss");
                            this.pickerOptions.selectableRange =`${Date().split(" ")[4]} - 23:59:59`;
                        }else{
                            this.pickerOptions.selectableRange =`00:00:00 - 23:59:59`;
                        }
                    })
                })
            }
        }
    },
    methods:{

        operationTypeChange(val){
            console.log("operationTypeChange",val);
            if(val===2){
                this.splitDialog=true
            }else{
                this.splitDialog=false
            }
        },
        splitClick(){
            this.operationType=2;
            this.splitDialog=true;
            console.log("splitClick");
        },
        splitAddClick(){
            let obj=this.type=='assembly'?
                {staffNos:[],splitQuantity:'',startTime:''}:
                {equipmentNo:'',splitQuantity:'',startTime:''}
            this.splitArr.push(obj)

        },
        taskDialogClose(){
            this.$emit('update:taskDialog',false);
            this.splitDialog=false;
        },
        //打开详情弹框时
        taskDialogOpen(){
            console.log('ddd',this.taskForm)
            this.splitArr=this.type=='assembly'?
            [
                {staffNos:this.taskForm.selectedStaffs.map((i)=>i.staffNo),splitQuantity:'',startTime:this.taskForm.procedureStartTime},
                {staffNos:[],splitQuantity:'',startTime:''},
            ]:
            [
                {equipmentNo:this.taskForm.selectedDevices,splitQuantity:'',startTime:this.taskForm.procedureStartTime},
                {equipmentNo:'',splitQuantity:'',startTime:''},
            ]
            this.operationType='';
            this.isLock=this.taskForm.procedureLockState==1;
        },
        //确定待修改的详情信息
        taskSubmit(){
            if(this.splitDialog){
                this.$message.warning({
                    showClose: true,
                    message:'请先确认拆分数据！',
                    duration:0
                });return;
            }
            // console.log(this.operationType,this.taskForm.processingRoutePriority)
            if(this.operationType===1 && this.taskForm.processingRoutePriority==undefined){
                this.$message.warning({
                    showClose: true,
                    message:'请选择工步优先级！',
                    duration:0
                })
                return;
            }
            if(this.operationType==''){
                this.editSubmit('');
            }else{
                this.algoDialog=true;
            }
            
        },
        //确认运算方法后提交
        editSubmit(type){
            let params={
                serialNumber:this.taskForm.serialNumber,
                schedulingNo:this.taskForm.schedulingNo,
                operationType:this.operationType,
                operationMethod:type,

                processingRoutePriority:this.taskForm.processingRoutePriority,

                procedureLockState:this.isLock? 1:0,

                procedureStartTime:this.taskForm.procedureStartTime,
                selectedDevices:this.type=='assembly'?'': this.taskForm.selectedDevices,
                selectedStaff:this.type=='assembly'? this.taskForm.selectedStaff:'',
                curSelectedStaff:this.type=='assembly'? this.taskForm.selectedStaffs[0].staffNo:'',

                splitQuantitys:this.splitArr,

                curSchedulingMethod:this.taskForm.curSchedulingMethod   
            }
            // console.log('editSubmit',type,params)
            this.$emit('taskSubmit',params);
            this.algoDialog=false;

        },
        //拆分任务数据验证确认
        splitCheck(){
            console.log('splitCheck',this.splitArr);
            
            let num=0;
            for(let i=0;i<this.splitArr.length;i++){
                if(this.splitArr[i].staffNos?.length<=0||this.splitArr[i].equipmentNo==""){
                    this.$message.warning({
                        showClose: true,
                        message:'当前有 人员/设备 未填写',
                        duration:0
                    });
                    return;
                }
                // if(this.type=='assembly'&& 
                //     this.splitArr[i].staffNos.length!=this.splitArr[0].staffNos.length){
                //     this.$message.warning({
                //         showClose: true,
                //         message:'拆分任务所选人员/设备数应为'+this.splitArr[0].staffNos.length,
                //         duration:0
                //     });
                //     return;
                // }
                if(this.splitArr[i].splitQuantity==""){
                    this.$message.warning({
                        showClose: true,
                        message:'当前有 安排数量 未填写',
                        duration:0
                    });
                    return;
                }
                if(isNaN(this.splitArr[i].splitQuantity)){
                    this.$message.warning({
                        showClose: true,
                        message:'安排数量请填写数字',
                        duration:0
                    });
                    return;
                }
                if(this.splitArr[i].splitQuantity==0){
                    this.$message.warning({
                        showClose: true,
                        message:'安排数量不能为0',
                        duration:0
                    });
                    return;
                }
                if(this.splitArr[i].startTime==""){
                    this.$message.warning({
                        showClose: true,
                        message:'当前有 开始时间 未填写',
                        duration:0
                    });
                    return;
                }
                num+=this.splitArr[i].splitQuantity;
            }
            let arr=this.splitArr.map(item=>{
                return item.equipmentNo;
            })
            if(this.type!='assembly'&&new Set(arr).size !== arr.length){
                this.$message.warning({
                    showClose: true,
                    message:'当前有设备编号重复！',
                    duration:0
                });
                return;
            }
            if(num!=this.taskForm.plannedQuantity){
                this.$message.warning({
                    showClose: true,
                    message:'当前拆分的数量之和与排产数量不符',
                    duration:0
                });
                return;
            }
            // console.log('splitArr',this.splitArr)
            this.splitDialog=false;
        },
    }

}
</script>

<style lang="scss" scoped>
.detail-wrapper{
    .bgGreen{
        ::v-deep .el-input__inner {
            background: rgba(27,206,98,0.55) !important;
            border: 1px solid #2DEB79!important;
                    }
    }
    .bgRed{
        ::v-deep .el-input__inner {
            background: rgba(255,77,79,0.55)!important;
            border: 1px solid #FF4D4F!important;
        }
    }
    ::v-deep .el-dialog{
        width: 1010px;
        // height: 810px;
        background: #232323;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 20px 50px;
        padding-right: 0px;
        padding-bottom: 40px;
        .el-dialog__header{
            padding: 0px;
        }
        .el-dialog__body{
            padding: 0px;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
            font-size: 30px;
        }
        .el-radio__inner{
            border: 1px solid #707070;
            width: 20px;
            height: 20px;
            &::after{
                background-color: #FFFFFF;
            }
        }
        .el-radio__label{
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        .el-radio{
            width: 150px;
            margin: 20px 30px 0px 0px;
            display: flex;
            align-items: center;
        }
        .el-radio-group{
            display: flex;
        }
        
    }
    //任务详情弹框
    .taskselect{
        text-align: left;
        .title{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #E8E8E8;
            margin: 10px 0px;
        }
        .formDiv{
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
        }
        ::v-deep .el-form{
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            .el-form-item__label {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #E8E8E8;
                padding: 0px;
            }
            .el-input__inner {
                width: 260px;
                background: #4F4F4F;
                border: 1px solid #4F4F4F;
                border-radius: 6px;
            }
            #bgBlack{background: #232323 !important;}
            #bgD,#bgD2,#bgD3{background: #4F4F4F !important;}
            // #bgD2{background: #4F4F4F !important;}
            .el-form-item{
                margin-bottom: 10px;
                width: 260px;
                margin-right: 60px;
            }
            .el-input__suffix{
                right: 14px;
                color: #B9B9B9 !important;
            }
            .el-input__prefix{
                left:224px
            }
            .el-select__tags-text{
                color: #fff;
            }
            .el-tag.el-tag--info {
                background-color: transparent;
                border-color: transparent;
            }
            
        }
        .formEditDiv{
            ::v-deep .el-input__inner {
                background: #232323;
            }
            .splitDialog {
                
                position: absolute;
                bottom: 170px;
                left: 120px;
                width: 730px;
                // height: 367px;
                background: #232323;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                box-sizing: border-box;
                padding: 30px 66px 10px 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .colseIcon{
                    font-size: 30px;
                    color: #E8E8E8;
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    cursor: pointer;
                }
                .colseIcon:hover{
                    color: #1BCE62;
                }
                .splitTitle{
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #E8E8E8;
                }
                .splitHead{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #E8E8E8;
                    >div{
                        box-sizing: border-box;
                        width: 170px;
                        margin-left: 30px;
                        padding-left: 10px;
                    }
                }
                ::v-deep .el-input__inner {
                    width: 176px;
                    height: 40px;
                    background: #232323;
                    border: 1px solid #4F4F4F;
                    border-radius: 6px;
                    padding: 0px 10px;
                }
                ::v-deep .el-input__suffix-inner {
                    height: 40px !important;
                    line-height: 40px;
                }
                ::v-deep .el-input{
                    width: 176px;
                    // height: 40px;
                }
                ::v-deep .el-input__prefix{
                    left:148px
                }
                .splitNo{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 8px 0px;
                    position: relative;
                    .taskno{
                        width: 58px;
                        text-align: right;
                    }
                    img{
                        width: 14px;
                        cursor: pointer;
                        position: absolute;
                        right: -24px;
                    }
                }
                .splitAdd{
                    width: 556px;
                    height: 40px;
                    background: #232323;
                    border: 1px dashed #4F4F4F;
                    color: #4F4F4F;
                    opacity: 1;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 30px;
                    align-self: flex-end;
                    margin: 10px 0px;
                    cursor: pointer;
                }
                // .splitAdd:hover{
                //     color: #1BCE62;
                // }
                .splitBtn{
                    align-self: flex-end;
                    margin-right: 0px;
                }
                
            }
        }
        .formLockDiv{
            margin-top: 20px;
            ::v-deep .el-checkbox__label{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #E8E8E8;
            }
            ::v-deep .el-checkbox__inner{
                border: 1px solid #4F4F4F;
                width: 16px;
                height: 16px;
                text-align: center;
                &::after {
                    // 里面的对钩
                    border-color: #fff;
                }
            }
            ::v-deep .el-checkbox {
                margin-right: 10px;
                .is-checked {
                    .el-checkbox__inner {
                        // 复选框的样式修改
                        background-color: #1BCE62;
                        border: 1px solid #1BCE62;
                        
                        &::after {
                            // 里面的对钩
                            border-color: #fff;
                            margin-left: 2px;
                        }
                    }
                }
            }
        }
  
        .btndiv,.splitBtn{
            text-align: right;
            margin: 0px 60px;
            .el-button:first-child{
                width: 90px;
                height: 40px;
                background: rgba(20, 20, 20, 0);
                border: 1px solid rgba(255, 255, 255, 0.2);
                opacity: 1;
                border-radius: 6px;
            }
            .el-button:last-child{
                width: 90px;
                height: 40px;
                background: #1BCE62;
                opacity: 1;
                border-radius: 6px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                
            }
        }
        
    }
}

</style>