<template>
    <el-dialog :visible="algoDialog" top="30vh" @close="$emit('update:algoDialog',false)">
        <div class="algoDiv">
            <div class="title"><img src="@/assets/images/gantt/type.png" alt=""> 请选择运算方式</div>
            <el-radio-group v-model="typeRadio">
                <el-radio :label="1">全局重新运算</el-radio>
                <el-radio :label="2">只运算改动设备</el-radio>
            </el-radio-group>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('update:algoDialog',false)">取 消</el-button>
                <el-button type="primary" @click="$emit('algoClick',typeRadio)">确 定</el-button>
            </span>
        </div>
        
    </el-dialog>
</template>

<script>
export default {
    props:{algoDialog:Boolean},
    data(){
        return{
            typeRadio:1
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    width: 426px !important;
    height: 250px;
    background: #232323;
    box-shadow: 0px 12px 30px 1px rgba(0,0,0,0.47);
    border-radius: 8px 8px 8px 8px;
    padding-left: 40px !important;
    padding-right: 40px !important;
   

    .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
        font-size: 30px;
    }
    .el-radio__inner{
        border: 1px solid #707070;
        width: 20px;
        height: 20px;
        &::after{
            background-color: #FFFFFF;
        }
    }
    .el-radio__label{
        font-size: 18px !important;
    }
    .el-radio{
        display: flex;
        align-items: center;
    }
}


.algoDiv{
    font-family: PingFang SC-Bold, PingFang SC;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    .title{
        font-size: 24px;
        font-weight: bold;
        color: #E8E8E8;
        display: flex;
        align-items: center;
        img{
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }

    }
    .dialog-footer{
        align-self: flex-end;
        margin-top: 40px;
        .el-button:first-child {
            width: 90px;
            height: 40px;
            background: rgba(20, 20, 20, 0);
            border: 1px solid rgba(255, 255, 255, 0.2);
            opacity: 1;
            border-radius: 6px;
            margin-top: 10px;
        }
    
        .el-button:last-child {
            width: 90px;
            height: 40px;
            background: #1BCE62;
            opacity: 1;
            border-radius: 6px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 12px;
            
            
        }
    }

}

</style>